<template>
  <div class="email_box">
    <div class="e_top_img">
      <img src="../../assets/img/email_img.png" alt="" />
    </div>
    <div class="e_main">
      <div class="e_title1">面试官，您好！</div>
      <div class="e_title2">
        由于候选人修改了时间，为了确保面试顺利，请回复是否同意修改时间：
      </div>
      <div class="e_title3">候选人提供的时间段为：</div>
      <div class="e_title3" v-if="start&&end">
        {{ time_format(start) }}
        ~
        {{ time_format(end) }}
      </div>
      <div class="e_select_box">
        <el-checkbox v-model="is_consent" @change="change_checkbox(1)" :label="1">同意</el-checkbox>
      </div>

      <div class="e_select_box">
        <el-checkbox v-model="is_consent" @change="change_checkbox(0)" :label="0">不同意</el-checkbox>
      </div>

      <div>
        <el-button type="primary" class="e_submit_btm" @click="confirm">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {isAggreeChangeTime} from '@/api/api';
export default {
  data() {
    return {
        start:"",
        end:"",
        is_consent:[],
        order_id:"",
        candidate_userid:"",
    };
  },
  mounted() {
    // https://workorder.hibuddy.com.cn/email_set_time?start_time=169921719303&end_time=1699237195303&order_id=xxx&candidate_userid=xxx
    if (window.location.href.indexOf("?") !== -1) {
        const url = new URL(window.location.href);
        const order_id = url.searchParams.get("order_id");
        const candidate_userid = url.searchParams.get("candidate_userid");
        this.order_id = order_id;
        this.candidate_userid = candidate_userid;

        const start = url.searchParams.get("start_time");
        const end = url.searchParams.get("end_time");
        if(!start||!end) return;
        this.start = JSON.parse(start);
        this.end = JSON.parse(end);
    }
  },
  methods: {
    change_checkbox(v) {
      this.is_consent = [];
      this.is_consent = [v];
    },
    async confirm() {
      if (!this.is_consent.length) {
        return this.msg("error", "请选择是否同意修改时间");
      }
      // console.log("is_agree",this.is_consent[0]);
      // console.log(typeof this.is_consent[0]);
      const res = await isAggreeChangeTime({
        order_id:this.order_id,
        candidate_userid:this.candidate_userid,
        is_agree:this.is_consent[0],
        });
        if(res.data.code!=200){
        return this.msg("error",res.data.msg);
        }
        this.msg("success","操作成功");
    },
  },
};
</script>

<style scoped>
.email_box{
  height: 100vh;
}
/* *{
    margin: 0;
    padding: 0;
    font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
} */
.e_top_img {
  /* height: 200px;
  background-color: pink; */
}
.e_top_img img {
  width: 100%;
}
.e_main {
  width: 75%;
  max-width: 800px;
  margin: 30px auto;
}
.e_title1 {
  color: #333;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.e_title2 {
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
.e_title3 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.e_main .e_title3:nth-child(3){
  margin-bottom: 5px;
  font-size: 14px;
}
.e_select_box {
  border-radius: 6px;
  background: #f4f5fa;
  box-sizing: border-box;
  padding: 35px;
  margin-bottom: 40px;
}
.is_c_box_1 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
}
.e_select_box .el-checkbox__input.is-checked + .el-checkbox__label,
.e_select_box .el-checkbox__label {
  color: #333;
  font-size: 16px;
}
.e_radio {
  margin-top: 15px;
}
.e_radio .el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
.time_center {
  box-sizing: border-box;
  padding: 0 15px;
}
.is_c_time {
  margin-top: 15px;
}
.time_btn_txt {
  margin-top: 10px;
  color: red;
  font-size: 12px;
  font-weight: 400;
}
.e_radio .el-radio {
  color: #a9a9a9;
}
.c2_inp {
  max-width: 400px;
}
.e_submit_btm,
.e_submit_btm:hover,
.e_submit_btm:focus,
.e_submit_btm:active,
.e_submit_btm:valid {
  min-width: 440px;
  height: 50px;
  background: #0a76e2;
  border-color: #0a76e2;
  color: #fff;
}

.is_c_time >>> .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    border-color: #DCDFE6;
    color: #606266;
    cursor: not-allowed;
}

</style>
